import { SortDirection } from '@angular/material/sort'
import { Fail } from 'apps/client/src/models/Fail'
import { ServiceDTO } from 'apps/client/src/models/Services'
import { ServiceConfig, ServiceConfigStatus } from './ServiceConfig'

export class ServicesState {
  loading?: boolean = true
  error?: Fail
  total?: number = 0
  items?: ServiceDTO[] = []
  serviceId?: string
  service?: ServiceDTO
  page?: number = 1
  size?: number = 25
  sort?: string
  order?: SortDirection
  more?: boolean = false
  config?: ServiceConfig<ServiceConfigStatus[] | string>[]
}
