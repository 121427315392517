import { createAction, props } from '@ngrx/store'
import { Fail } from '../../../models/Fail'
import { ServiceComment, ServiceDTO } from '../../../models/Services'
import { ServiceConfig } from '../model/ServiceConfig'
import { ServicesState } from '../model/ServicesState'

export const LOAD_MORE_SERVICES = 'LOAD_MORE_SERVICES'

export const GET_SERVICE = 'GET_SERVICE'

export const CREATE_SERVICE = 'CREATE_SERVICE'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'

export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT'

export const UPDATE_SERVICE = 'UPDATE_SERVICE'

export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL'

export const OPEN_NEW_FORM = 'OPEN_NEW_FORM'
export const CLOSE_NEW_FORM = 'CLOSE_NEW_FORM'

export const CLOSE_DETAIL = 'CLOSE_DETAIL'

export const DELETE_SERVICE = 'DELETE_SERVICE'
export const RESET_DETAIL = 'RESET_DETAIL'

export const GET_CONFIGS = 'GET_CONFIGS'
export const UPSERT_CONFIG = 'UPSERT_CONFIG'
export const SERVICE_PARTS = 'SERVICE_PARTS'
export const SERVICE_DIFF = 'SERVICE_DIFF'

export const GET_SERVICE_COMMENTS = 'GET_SERVICE_COMMENTS'
export const POST_SERVICE_COMMENT = 'POST_SERVICE_COMMENT'
export const DELETE_SERVICE_COMMENT = 'DELETE_SERVICE_COMMENT'
export const CREATE_SERVICE_OFFER = 'CREATE_SERVICE_OFFER'

export const ServicesActions = {
  /** loads service detail by id */
  getService: createAction(GET_SERVICE, (id: string) => ({ id })),
  /** creates new service */
  createService: createAction(CREATE_SERVICE, (service: ServiceDTO, serviceId?: string) => ({
    payload: { service, id: serviceId },
  })),
  /** successful service creation callback  */
  createServiceSuccess: createAction(CREATE_SERVICE_SUCCESS, props<ServicesState>()),
  /** successful load of services list callback */
  getServicesSuccess: createAction(GET_SERVICES_SUCCESS, props<ServicesState>()),
  /** failed load of services list callback */
  getServicesFailure: createAction(GET_SERVICES_FAIL, (failure: Fail) => ({ payload: { failure } })),
  closeNewForm: createAction(CLOSE_NEW_FORM),
  closeDetail: createAction(CLOSE_DETAIL),
  deleteService: createAction(DELETE_SERVICE, props<{ id: string }>()),
  resetDetail: createAction(RESET_DETAIL),
  updateService: createAction(UPDATE_SERVICE, (id: string, service: Partial<ServiceDTO>) => ({ id, service })),
  getConfigs: createAction(GET_CONFIGS),
  upsertConfigs: createAction(UPSERT_CONFIG, props<ServiceConfig>()),
  getServiceParts: createAction(SERVICE_PARTS, props<{ id: string }>()),
  getServiceDiff: createAction(SERVICE_DIFF, props<{ id: string }>()),
  getServiceComments: createAction(GET_SERVICE_COMMENTS, props<{ id: string; userId?: number }>()),
  postServiceComment: createAction(POST_SERVICE_COMMENT, props<{ id: string; comment: string; commentId?: string }>()),
  deleteServiceComment: createAction(DELETE_SERVICE_COMMENT, props<{ id: string; comment: ServiceComment }>()),
  createOffer: createAction(
    CREATE_SERVICE_OFFER,
    (id: string, update: Partial<ServiceDTO>, callback?: (response: Record<string, unknown>) => void) => ({ id, update, callback }),
  ),
}

export default ServicesActions
