import { ActionReducer, createReducer, createSelector, on } from '@ngrx/store'
import { AppState } from '../../../core/reducers/index'
import { BaseConfigStatus, ServiceConfig, ServiceConfigStatus } from '../model/ServiceConfig'
import { ServicesState } from '../model/ServicesState'
import { ServicesActions } from './services.actions'

const _servicesReducer = createReducer(
  new ServicesState(),
  on(ServicesActions.getService, (state, { id }) => ({ ...state, loading: true, serviceId: id })),
  on(ServicesActions.getServicesSuccess, (state, payload) => ({ ...state, ...payload, loading: false })),
  on(ServicesActions.getServicesFailure, (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.failure,
  })),
  on(ServicesActions.closeNewForm, state => ({ ...state, new: false })),
  on(ServicesActions.createService, (state, payload) => ({ ...state, ...payload, loading: true })),
  on(ServicesActions.createServiceSuccess, (state, payload) => ({
    ...state,
    ...payload,
    loading: true,
    new: false,
  })),
  on(ServicesActions.closeDetail, state => ({ ...state, service: undefined, serviceId: undefined, new: false })),
  on(ServicesActions.deleteService, (state, { id }) => ({
    ...state,
    loading: true,
    ...(state.service?._id === id ? { service: undefined } : {}),
  })),
  on(ServicesActions.updateService, (state, { service }) => ({
    ...state,
    service: { ...state.service, ...service },
    loading: true,
    serviceId: undefined,
  })),
  on(ServicesActions.postServiceComment, state => ({ ...state, loading: true })),
  on(ServicesActions.deleteServiceComment, state => ({ ...state, loading: true })),
  on(ServicesActions.createOffer, state => ({ ...state, loading: true })),
)

export const selectFeature = (state: AppState) => state.services

export const selectService = createSelector(selectFeature, state => state.service)

export const selectStateConfigs = createSelector(
  selectFeature,
  state => (state.config || []).filter(c => c.config === 'state') as ServiceConfig<ServiceConfigStatus[]>[] | undefined,
)

export const selectInternalConfigs = createSelector(
  selectFeature,
  state => (state.config || []).filter(c => c.config === 'internal_state') as ServiceConfig<BaseConfigStatus[]>[] | undefined,
)

export const selectMailConfigs = createSelector(
  selectFeature,
  state => (state.config || []).filter(c => c.config === 'mail') as ServiceConfig<string>[] | undefined,
)

export const selectServiceDiff = createSelector(selectFeature, state => state.service?.history)

export const selectServiceComments = createSelector(selectFeature, state => state.service?._comments)

export const selectServiceLoading = createSelector(selectFeature, state => state.loading ?? false)

export const services: ActionReducer<ServicesState> = (state, action) => _servicesReducer(state, action)
